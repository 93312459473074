import React, { useContext, useState } from 'react';
import styles from './Locality.module.css';
import { useFormikContext } from 'formik';
import { LocalitiesContext } from '../../../../contexts/LocalitiesContext';

export default function LocalityPopover({ nameLocality }) {
  const { localities } = useContext(LocalitiesContext);
  const { setFieldValue } = useFormikContext();

  const [searchTerm, setSearchTerm] = useState('');

  const filteredLocalities = localities.filter(locality =>
      locality.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
      <div className={styles.container}>
        <h3 className={styles.title}>Escolha uma localidade</h3>

        <input
            type="text"
            placeholder="Digite para buscar"
            className={styles.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
        />
        
        {filteredLocalities.map((l) => (
            <p
                key={l.id}
                className={styles.item}
                onClick={() => {
                  setFieldValue(nameLocality, l.id);
                }}
            >
              {l.name}
            </p>
        ))}
      </div>
  );
}
