import { DownOutlined } from '@ant-design/icons';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { AutoComplete, Col, Dropdown, message, Modal, Row, Input} from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import AddressAutocomplete from 'react-google-autocomplete';
import { FaMapMarkerAlt, FaUser } from 'react-icons/fa';
import { useMutation } from 'urql';
import { LocalitiesContext } from '../../contexts/LocalitiesContext';
import styles from './AddressModal.module.css';
import LocalitiesPopover from './LocalitiesPopover';

const UPDATE_ADDRESS = gql`
  mutation($id: uuid!, $address: address_set_input!) {
    update_address(where: { id: { _eq: $id } }, _set: $address) {
      affected_rows
    }
  }
`;

const INSERT_ADDRESS = gql`
  mutation($address: [address_insert_input!]!) {
    insert_address(objects: $address) {
      returning {
        id
      }
    }
  }
`;
const UPDATE_PASSENGER = gql`
  mutation($id: uuid!, $obj: passenger_set_input) {
    update_passenger(where: { id: { _eq: $id } }, _set: $obj) {
      returning {
        id
      }
    }
  }
`;

export default function AddressModal({ visible, setVisible, selectedPassenger }) {
  const [address, setAddress] = useState(selectedPassenger['address']);
  const [locality, setLocality] = useState(selectedPassenger['locality']);
  const [addressId, setAddressId] = useState('');
  const [addressString, setAddressString] = useState('');
  const [address_result, update_address] = useMutation(UPDATE_ADDRESS);
  const [, insert_address] = useMutation(INSERT_ADDRESS);
  const [, update_passenger] = useMutation(UPDATE_PASSENGER);
  const { localities } = useContext(LocalitiesContext);
  const [options, setOptions] = useState([]);

  const mutate_address = (id, address) => {
    update_address({ id, address })
        .then((e) => {
          console.log(e);
        })
        .catch((e) => console.log(e));
  };

  const mutate_locality = (id, locality_id) => {
    update_passenger({ id: id, obj: { locality_id } }).then((e) => {
      message.success('Endereço do colaborador atualizado');
    });
  };

  const create_address = (address) => {
    insert_address({ address })
        .then((r) => {
          if (r.data) {
            const { id } = r.data.insert_address.returning[0];
            update_passenger({ id: selectedPassenger.id, obj: { address_id: id, locality_id: locality?.id } }).then((r) => {
              if (r.data) {
                message.success('Endereço criado e associado ao colaborador');
              } else {
                message.error('Erro ao atribuir endereço ao passageiro');
              }
            });
          } else {
            message.error('Erro ao criar endereço');
          }
        })
        .catch((e) => console.log(e));
  };

  useEffect(() => {
    setAddress(selectedPassenger.address);
    selectedPassenger.address && setAddressId(selectedPassenger.address.id);
    selectedPassenger.address && setAddressString(selectedPassenger.address.description);
  }, [selectedPassenger]);

  const { name } = selectedPassenger || {};

  const onOk = () => {
    if (!addressId) {
      create_address([address]);
    } else {
      mutate_address(addressId, address);
      mutate_locality(selectedPassenger.id, locality.id);
    }

    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const singleClick = ({ latLng }) => {
    setAddress(undefined);
    const geocoder = new window.google.maps.Geocoder();
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    let description = '';
    let address_components;
    let city;
    let state;
    let zipcode = null;
    let complement = null;

    geocoder.geocode({ location: latLng }, function (results, status) {
      if (status === 'OK') {
        if (results[0]) {
          description = results[0]['formatted_address'];
          address_components = results[0]['address_components'];
          address_components.forEach((el) => {
            const types = el.types;
            if (types.find((t) => t === 'administrative_area_level_2')) {
              city = el.long_name;
            }
            if (types.find((t) => t === 'administrative_area_level_1')) {
              state = el.long_name;
            }
            if (types.find((t) => t === 'postal_code')) {
              zipcode = el.long_name;
            }
            if (types.find((t) => t === 'sublocality_level_1')) {
              complement = el.long_name;
            }
          });

          const obj = { latitude, longitude, description, city, state, zipcode, complement };

          setAddress(obj);
          setAddressString(obj.description);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  };

  const doubleClick = () => {
    return;
  };

  const handleSearch = (value) => {
    const filteredOptions = localities
        .filter((locality) => locality.name.toLowerCase().includes(value.toLowerCase()))
        .map((locality) => ({
          value: locality.name,
          label: (
              <div>
                {locality.name}
                <DownOutlined className={styles.localityIcon} />
              </div>
          ),
        }));
    setOptions(filteredOptions);
  };

  const handleSelect = (value) => {
    const selectedLocality = localities.find((locality) => locality.name === value);
    setLocality(selectedLocality);
  };

  let clickCount = 0;
  let singleClickTimer = '';

  const handleClicks = (event) => {
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(() => {
        clickCount = 0;
        singleClick(event);
      }, 300);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      doubleClick();
    }
  };

  if (address_result.error) return <span style={{ color: 'red', fontWeight: 'bold' }}>Erro ao atualizar endereço. Contate o suporte</span>;

  return (
      <Modal
          destroyOnClose={true}
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
          okText="Salvar endereço"
          cancelText="Cancelar"
          title="Endereço do colaborador"
          width={860}
      >
        <div>
          <Row justify="start" gutter={32}>
            <Col span={10}>
              <label>
                <FaUser className={styles.icon} />
                <span className={styles.label}>Nome</span>
              </label>
              <p className={styles.value}>{name}</p>
            </Col>
            <Col span={12}>
              <label>
                <FaMapMarkerAlt className={styles.icon} />
                <span className={styles.label}>Endereço</span>
              </label>
              <AddressAutocomplete
                  style={{ marginTop: '0.5rem' }}
                  className="ant-input ant-input-lg"
                  type="text"
                  value={addressString}
                  onChange={(e) => setAddressString(e.target.value)}
                  placeholder="Pesquisar endereço do colaborador"
                  onPlaceSelected={(place) => {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    const description = place.formatted_address;
                    setAddress(() => {
                      let city;
                      let state;
                      let zipcode = null;
                      let complement = null;
                      place.address_components.forEach((el) => {
                        const types = el.types;
                        if (types.find((t) => t === 'administrative_area_level_2')) {
                          city = el.long_name;
                        }
                        if (types.find((t) => t === 'administrative_area_level_1')) {
                          state = el.long_name;
                        }
                        if (types.find((t) => t === 'postal_code')) {
                          zipcode = el.long_name;
                        }
                        if (types.find((t) => t === 'sublocality_level_1')) {
                          complement = el.long_name;
                        }
                      });

                      return { latitude: lat, longitude: lng, description, city, state, zipcode, complement };
                    });
                    setAddressString(description);
                  }}
                  types={['address']}
                  componentRestrictions={{ country: 'br' }}
              />
              {/*<Dropdown overlay={<LocalitiesPopover localities={localities} setLocality={setLocality} />}>*/}
              {/*  <p className={styles.locality}>*/}
              {/*    Localidade:*/}
              {/*    <span className={styles.localityName}>*/}
              {/*    {' '}*/}
              {/*      {locality?.name || 'Não especificado'}*/}
              {/*      <DownOutlined className={styles.localityIcon} />*/}
              {/*  </span>*/}
              {/*  </p>*/}
              {/*</Dropdown>*/}
              <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  style={{ width: '100%' }}
                  defaultValue={locality?.name}
              >
                <Input.Search size="large" placeholder="Digite o nome da localidade" />
              </AutoComplete>

            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className={styles.instruction}>Você pode também clicar no mapa para atualizar o endereço desse colaborador</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={styles.map}>
                <GoogleMap
                    id="direction-example"
                    mapContainerStyle={{
                      height: '100%',
                      width: '100%',
                    }}
                    zoom={15}
                    center={address ? { lat: address.latitude, lng: address.longitude } : { lat: -12.687755, lng: -38.268275 }}
                    onDblClick={handleClicks}
                    onClick={handleClicks}
                >
                  {address && <Marker position={{ lat: address.latitude, lng: address.longitude }} />}
                </GoogleMap>
              </div>
            </Col>
          </Row>
        </div>
        <div></div>
      </Modal>
  );
}
