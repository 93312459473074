import { Button, Input, Modal, notification, AutoComplete } from 'antd';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'urql';
import styles from './EditUserModal.module.css';
import { REQUIRED_FIELD_MESSAGE } from "../../../constants";

const UPDATE_PASSENGER_MUTATION = gql`
    mutation($id: uuid!, $passenger: passenger_set_input) {
        update_passenger(where: { id: { _eq: $id } }, _set: $passenger) {
            returning {
                id
            }
        }
    }
`;

export default function EditUserModal({ user, setUser, isVisible, setIsVisible, costCenters }) {
    const [submitting, setSubmitting] = useState(false);
    const [, updatePassenger] = useMutation(UPDATE_PASSENGER_MUTATION);
    const [newName, setNewName] = useState('');
    const [newRegistration, setNewRegistration] = useState('');
    const [newCostCenterId, setNewCostCenterId] = useState('');
    const [options, setOptions] = useState([]);
    const [costCenterValue, setCostCenterValue] = useState('');

    const close = () => {
        setIsVisible(false);
        setUser(null);
    };

    const submit = async event => {
        event.preventDefault();

        if (submitting) return;

        setSubmitting(true);

        try {
            if (!newName.trim() || !newRegistration.trim() || !newCostCenterId.trim())
                throw new Error('Form edit error');

            const { data: { update_passenger: { returning: [{ id: currentId }] } } } = await updatePassenger({
                id: user.id,
                passenger: { name: newName, registration: newRegistration, cost_center_id: newCostCenterId }
            });

            if (!currentId) throw new Error('Request edit error');

            close();
            notification.success({ message: 'Sucesso ao atualizar usuário.' });
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Ocorreu um problema ao atualizar usuário, reveja o formulário e tente novamente.',
                description: 'Matrícula pode estar já sendo usada.'
            });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        setNewName(user?.name);
        setNewRegistration(user?.registration);
        setNewCostCenterId(user?.costCenterId);

        if (user?.costCenterId) {
            const selectedCostCenter = costCenters.find(cc => cc.id === user.costCenterId);
            setCostCenterValue(selectedCostCenter ? `${selectedCostCenter.number} - ${selectedCostCenter.description}` : '');
        }
    }, [user, costCenters]);

    const handleSearch = (value) => {
        setCostCenterValue(value); // Atualiza o valor conforme o usuário digita

        if (!value) {
            // Quando o valor estiver vazio, lista todos os centros de custo
            setOptions(
                costCenters.map(({ id, number, description }) => ({
                    value: id,
                    label: `${number} - ${description}`,
                }))
            );
            return;
        }

        // Caso contrário, filtra os centros de custo conforme o valor digitado
        const filteredOptions = costCenters
            .filter(({ number, description }) =>
                number.includes(value) || description.toLowerCase().includes(value.toLowerCase())
            )
            .map(({ id, number, description }) => ({
                value: id,
                label: `${number} - ${description}`,
            }));

        setOptions(filteredOptions);
    };
    const handleSelect = value => {
        setNewCostCenterId(value);
        const selectedCostCenter = costCenters.find(cc => cc.id === value);
        setCostCenterValue(selectedCostCenter ? `${selectedCostCenter.number} - ${selectedCostCenter.description}` : '');
    };

    return (
        <Modal
            destroyOnClose={true}
            visible={isVisible}
            onCancel={close}
            footer={null}
            title={user ? `Editar usuário: ${user.name}` : ''}
            width={800}
        >
            <form onSubmit={submit} className={'form'}>
                <div className={styles.infoFields}>
                    <div className={'field'}>
                        <label htmlFor={'fieldName'}>Nome:</label>
                        <Input
                            id={'fieldName'}
                            type="text"
                            placeholder="Coloque o nome"
                            size="large"
                            value={newName}
                            maxLength={200}
                            required={true}
                            onChange={event => setNewName(event.currentTarget.value)}
                        />
                        <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
                    </div>

                    <div className={'field'}>
                        <label htmlFor={'fieldRegistration'}>Matrícula:</label>
                        <Input
                            id={'fieldRegistration'}
                            type="number"
                            placeholder="Coloque a matrícula"
                            size="large"
                            value={newRegistration}
                            maxLength={10}
                            required={true}
                            onChange={event => setNewRegistration(event.currentTarget.value)}
                        />
                        <span className={'hint'}>{REQUIRED_FIELD_MESSAGE}</span>
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="fieldCostCenter">Centro de custo:</label>
                    <AutoComplete
                        id={'fieldCostCenter'}
                        placeholder="Escolha o centro de custo"
                        options={options}
                        value={costCenterValue}
                        onSearch={handleSearch} // Filtra os resultados conforme o usuário digita
                        onSelect={handleSelect} // Atualiza o estado com o valor selecionado
                        style={{ width: '100%' }}
                    >
                        <Input size="large" />
                    </AutoComplete>
                    <span className="hint">{REQUIRED_FIELD_MESSAGE}</span>
                </div>

                <div className={styles.actions}>
                    <Button htmlType={'button'} onClick={close}>Fechar</Button>
                    <Button type="primary" htmlType={'submit'} loading={submitting} disabled={submitting}>Salvar</Button>
                </div>
            </form>
        </Modal>
    );
}
