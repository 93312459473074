import { flatten } from 'lodash';
import moment from 'moment';
import { isIllegalSolicitation } from './isIllegalSolicitation';
import { generateSolicitationInfosModel } from "../../../helpers/generate-models";
import { TIME_AVAILABLE_COST } from "../../../constants";

export const generateExcelTrips = (trips) => {
  const sanitizedTrips = trips.map((trip) => {
    const solicitations = trip.solicitations;

    const duplicatedTrips = solicitations.map((solicitation) => {
      const extremCost = trip.costs.find((cost) => cost?.extra_cost_type?.name === 'EXTREMO');
      const viaCosts = trip.costs.filter((cost) => cost?.extra_cost_type?.name === 'VIA').reduce((a, b) => a + b.value, 0);
      const waitCost = trip.costs.filter(cost => cost?.extra_cost_type?.name === TIME_AVAILABLE_COST).reduce((accumulator, cost) => accumulator + cost.value, 0);
      const parkingCost = trip.costs.filter((cost) => cost?.provenience === 'PARKING').reduce((a, b) => a + b.value, 0);
      const baseCost = trip.costs.filter((cost) => cost?.extra_cost_type === null && cost?.provenience === null).reduce((a, b) => a + b.value, 0);

      return {
        ...trip,
        totalCost: trip.costs.reduce((a, b) => a + b.value, 0),
        extremCost: extremCost?.value || 0,
        viaCosts,
        baseCost,
        waitCost,
        parkingCost,
        solicitation,
      };
    });

    return duplicatedTrips;
  });

  const flatTrips = flatten(sanitizedTrips);

  if (typeof flatTrips !== 'undefined') {
    const formatTrips = flatTrips.map((trip) => ({
      cooperative: trip?.cooperative?.name ?? '',
      cost_center_number: trip?.solicitation?.cost_center?.number ?? '',
      cost_center_description: trip?.solicitation?.cost_center?.description ?? '',
      cost_center_id: trip?.solicitation?.cost_center?.id ?? '',
      totalCost: trip?.totalCost != null && trip?.solicitations?.length ? Number(trip.totalCost / trip.solicitations.length) : 0,
      extremCost: trip?.extremCost != null && trip?.solicitations?.length ? Number(trip.extremCost / trip.solicitations.length) : 0,
      viaCost: trip?.viaCosts != null && trip?.solicitations?.length ? Number(trip.viaCosts / trip.solicitations.length) : 0,
      waitCost: trip?.waitCost != null && trip?.solicitations?.length ? Number(trip.waitCost / trip.solicitations.length) : 0,
      parkingCost: trip?.parkingCost != null && trip?.solicitations?.length ? Number(trip.parkingCost / trip.solicitations.length) : 0,
      baseCost: trip?.baseCost != null && trip?.solicitations?.length ? Number(trip.baseCost / trip.solicitations.length) : 0,
      destination: trip?.solicitation?.destination?.address ?? '',
      destination_locality: trip?.solicitation?.destination_locality?.name ?? '',
      origin: trip?.solicitation?.origin?.address ?? '',
      origin_locality: trip?.solicitation?.origin_locality?.name ?? '',
      reason: trip?.solicitation?.reason?.description ?? '',
      solicitant_name: trip?.solicitation?.user?.passenger?.name ?? '',
      solicitation_number: trip?.solicitation?.number ?? '',
      state: trip.charged_at ? 'Faturado' : trip?.approved_at ? 'Aprovado' : 'Pendente',
      trip_date: trip?.trip_date ? moment(trip.trip_date).format('DD/MM/YY') : '',
      trip_number: trip?.number ?? '',
      trip_time: trip?.time ?? '',
      isIllegal: trip?.solicitation ? (isIllegalSolicitation(trip.solicitation) ? 'Sim' : 'Não') : 'Não',
      explain_why_no_default_way: trip?.solicitation?.explain_why_no_default_way ?? '',
      observation: trip?.solicitation?.observation ?? '',
      ...generateSolicitationInfosModel(trip?.solicitation ?? {})
    }));

    return formatTrips;
  } else {
    return [];
  }

};
